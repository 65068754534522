import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loading from '@components/utils/Loading'
import { authenticationSelector } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'

export const withAuthenGuard = (Component: React.FC<any>) => {
  function HOC(props: any) {
    const navigate = useNavigate()
    const { isAuthentication } = useSelector(authenticationSelector)

    useEffect(() => {
      if (!isAuthentication) {
        // eslint-disable-next-line no-restricted-globals
        location.href = 'https://demo.khotbot.com/auth/login'
      }
    }, [isAuthentication, navigate])

    if (!isAuthentication) {
      return <Loading text='Loading' />
    }

    return <Component {...props} />
  }
  return HOC
}
