import React from 'react'
import { AvatarIcon, CameraIcon } from '@components/Icons/Icons'
import withProfileImage from './withProfileImage'
import { ProfileImageProps } from './interface'

function ProfileImage({
  path,
  className = 'w-[40px] h-[40px]',
  avatarClassName = 'text-gray-secondary',
  imageClassName = 'rounded-full',
  children,
  isPicker,
  pickerRef,
  previewImage,
  handlePickerClick,
  handleSelectImage,
}: ProfileImageProps) {
  return (
    <div className={`rounded-full relative ${className}`}>
      {isPicker && (
        <>
          <input
            type='file'
            ref={pickerRef}
            className='hidden'
            onChange={handleSelectImage}
            accept='image/*'
          />
          <CameraIcon
            className='absolute bottom-0 right-0 rounded-full h-[18px] w-[18px] p-[1.5px] border-1 border-black bg-[#F4F5FA] transform translate-x-1'
            onClick={handlePickerClick}
          />
        </>
      )}
      {path || previewImage ? (
        <img
          src={previewImage ?? path}
          alt='Profile'
          className={`w-full h-full object-cover ${imageClassName} `}
        />
      ) : (
        <AvatarIcon className={`w-full h-full ${avatarClassName}`} />
      )}
      {children}
    </div>
  )
}

export default withProfileImage(ProfileImage)
