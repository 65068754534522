import { Field, Form, Formik } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import { withUnAuthenGuard } from '@libs/hoc/withUnAuthenGuard'
import PrimaryButton from '@components/buttons/PrimaryButton'
import InputField from '@components/form/InputField/InputField'
import Otp from '@features/authentication/components/Otp/Otp'
import { FieldPropsType } from '@interfaces/FieldPropsType'
import PhoneNumberInputField from '@components/form/PhoneNumberInputField/PhoneNumberInputField'
import { RECAPTCHA_SITE_KEY } from '@config/config'
import React from 'react'
import { AuthenticationRoutesPath, HomeRoutesPath } from '@config/Router'
import { useNavigate } from 'react-router-dom'
import { useResponsive } from '@hooks/useResponsive'
import { RegisterPageProps } from './interface'
import withRegisterPage from './withRegisterPage'

function RegisterPage({
  handleRegisterFormSubmit,
  validationSchema,
  activeStep,
  registerForm,
  changePhoneNumberStep,
  createUser,
  openPrivacyModal,
  openTermsOfServiceModal,
  changeToRegisterStep,
}: RegisterPageProps) {
  const navigate = useNavigate()
  const { isMobile, isTablet } = useResponsive()
  const isDesktop = !(isMobile || isTablet)
  return (
    <div>
      {activeStep === 'register' && (
        <Formik
          initialValues={registerForm}
          onSubmit={handleRegisterFormSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ errors }) => (
            <Form>
              {!isDesktop && (
                <div className='flex justify-center h-[80px] mb-[30px]'>
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <img
                    src='/logoHorizontal.png'
                    alt='logo-horizontal'
                    className='h-[80px] w-fit cursor-pointer'
                    onClick={() => navigate(HomeRoutesPath.home.path)}
                  />
                </div>
              )}
              <div className='text-white text-4xl font-bold mb-[12px]'>
                Register
              </div>
              <div className='text-white text-base mb-[20px]'>
                Welcome to Khotbot
              </div>
              <div className='flex flex-col space-y-[20px] mb-[24px]'>
                <InputField
                  name='name'
                  label='Name'
                  type='text'
                  placeholder='Enter name'
                  required
                />
                <InputField
                  name='email'
                  label='Email'
                  type='text'
                  placeholder='Enter email'
                  required
                />
                {/* <InputField */}
                {/*  name='lineId' */}
                {/*  label='Line ID' */}
                {/*  type='text' */}
                {/*  placeholder='Line ID (Optional)' */}
                {/* /> */}
                <InputField
                  name='username'
                  label='Username'
                  type='text'
                  placeholder='Enter username'
                  required
                />
                <InputField
                  name='password'
                  label='Password'
                  type='password'
                  placeholder='Minimum 8 digits with A-Z, a-z, 0-9'
                  required
                />
                <InputField
                  name='confirmPassword'
                  label='Confirm password'
                  type='password'
                  placeholder='Confirm your password'
                  required
                />
                {/* <InputField */}
                {/*  name='referralCode' */}
                {/*  label='Referral Code' */}
                {/*  type='text' */}
                {/*  placeholder='Referral Code (Optional)' */}
                {/* /> */}
                <PhoneNumberInputField />
                <div className='flex flex-col items-center mt-[16px]'>
                  <Field name='recaptchaToken'>
                    {({ field, meta, form }: FieldPropsType<any>) => (
                      <>
                        <ReCAPTCHA
                          sitekey={RECAPTCHA_SITE_KEY}
                          onChange={(value: string) => {
                            form.setFieldValue('recaptchaToken', value)
                          }}
                          size='normal'
                        />
                        {errors.recaptchaToken && (
                          <div className='mt-[2px] text-xs text-red top-full'>
                            {errors.recaptchaToken}
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </div>
              </div>

              <p className='text-[12px] mb-[16px]'>
                By creating an account, you are agreeing to our
                <br />
                <span
                  className='underline text-primary cursor-pointer'
                  onClick={openTermsOfServiceModal}
                >
                  Terms of Service
                </span>
                <span> and </span>
                <span
                  className='underline text-primary cursor-pointer'
                  onClick={openPrivacyModal}
                >
                  Privacy Policy
                </span>
              </p>
              <PrimaryButton
                type='submit'
                className='w-full rounded-[6px] py-[8px] mb-[16px]'
              >
                <p className='text-lg font-bold'>Register</p>
              </PrimaryButton>

              <div className='py-[8px]'>
                <span>
                  {`Already have an account? `}
                  <span
                    className='ml-[4px] text-primary underline cursor-pointer'
                    onClick={() =>
                      navigate(AuthenticationRoutesPath.login.absolutePath)
                    }
                  >
                    Sign in
                  </span>
                </span>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {activeStep === 'otp' && (
        <Otp
          phoneNumber={registerForm.phoneNumber.slice(
            registerForm.countryCode.toString().length
          )}
          phoneNumberCountryCode={registerForm.countryCode}
          changePhoneNumberStep={changePhoneNumberStep}
          onSuccess={createUser}
          isShowLogo={!isDesktop}
        />
      )}
      {activeStep === 'phoneNumber' && (
        <Formik
          initialValues={registerForm}
          onSubmit={handleRegisterFormSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {() => (
            <Form className='flex flex-col h-4/5-screen desktop:justify-center mobile:pt-[50px]'>
              {!isDesktop && (
                <div className='flex justify-center h-[80px] mobile:mb-[30px] mb-[50px]'>
                  <img
                    src='/logoHorizontal.png'
                    alt='logo-horizontal'
                    className='h-[80px] w-fit '
                  />
                </div>
              )}
              <div className='text-white text-4xl font-bold mb-[25px]'>
                Enter new phone number
              </div>
              <PhoneNumberInputField />
              <PrimaryButton
                type='submit'
                className='w-full rounded-[6px] h-[48px] mt-[20px] mb-[20px]'
              >
                <p className='text-lg font-bold'>Request OTP</p>
              </PrimaryButton>
              <div className='py-[8px]'>
                <span>
                  {`Back to `}
                  <span
                    className='ml-[4px] text-primary underline cursor-pointer'
                    onClick={changeToRegisterStep}
                  >
                    Register
                  </span>
                </span>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default withUnAuthenGuard(withRegisterPage(RegisterPage))
