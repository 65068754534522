import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  OtherRoutesPath,
  ProfitRoutesPath,
  TrendingCoinRoutesPath,
} from '@config/Router'

import { authenticationSelector } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import TotalProfit from '@components/Profit/TotalProfit/TotalProfit'
import {
  BurgerIcon,
  CalculatorIcon,
  CrownWhiteIcon,
  PasswordIcon,
  PlugConnectedIcon,
} from '@components/Icons/Icons'
import { usePagination } from '@hooks/usePagination'
import AppTickerTape from '@components/AppTickerTape/AppTickerTape'
import ProfileImage from '@components/ProfileImage/ProfileImage'
import ProfitByBot from '@components/Profit/ProfitByBot/ProfitByBot'
import TrendingCoins from '@components/TrendingCoin/TrendingCoins'
import HelperButton from '@features/home/components/HelperButton/HelperButton'
import { useModal } from '@components/Modal/context/ModalContext'
import React, { useState } from 'react'
import HelperMenusModal from '@features/home/components/HelperMenusModal/HelperMenusModal'
import { useResponsive } from '@hooks/useResponsive'
import { ProfitType } from '@models/Profit/ProfitType'
import { BotMarketType } from '@interfaces/BotMarketEnum'
import { OptionType } from '@interfaces/OptionType'
import SegmentedControl from '@components/SegmentedControl/SegmentedControl'
import OutlinedButton from '@components/buttons/OutlinedButton'
import ActiveAnnouncement from '../components/ActiveAnnouncement/ActiveAnnouncement'
import HomeTopFiveUsers from '../components/HomeTopFiveUsers/HomeTopFiveUsers'
import Banner from '../components/Banner/Banner'

const marketTypeOptions: OptionType[] = [
  {
    label: 'Futures',
    value: BotMarketType.FUTURE,
  },
  {
    label: 'Spot',
    value: BotMarketType.SPOT,
  },
]
const HomePage = () => {
  const { profile } = useSelector(authenticationSelector)
  const { page: profitByBotPage, pageChange: profitByBotChange } =
    usePagination()
  const [botMarketType, setBotMarketType] = useState<BotMarketType>(
    BotMarketType.FUTURE
  )
  const navigate = useNavigate()
  const { openModal: openOtherModal, handleToggleModal } = useModal({
    component: () => <HelperMenusModal toggleModal={handleToggleModal} />,
  })
  const { isMobile } = useResponsive()

  return (
    <div
      className='
      mobile:px-[23px]
      mobile:max-w-[700px]  max-w-[1000px]
      mx-auto'
    >
      {/* ---- User ---- */}
      <div className='flex justify-between mb-7 space-x-1 items-center'>
        <div
          className='flex space-x-2 items-center cursor-pointer'
          onClick={() => navigate(OtherRoutesPath.profileSetting.absolutePath)}
        >
          <ProfileImage
            path={profile?.profileImgPath ?? ''}
            className='h-[45px] w-[45px] flex-shrink-0'
            avatarClassName='h-[45px] w-[45px] text-gray-secondary'
          />
          <p className='text-base font-bold'>{profile?.name}</p>
        </div>
        <div className='flex-1' />
        <OutlinedButton
          type='button'
          className='p-2 rounded-md hover:border-primary hover:text-primary'
          onClick={() =>
            window.open('https://app.khotbot.com/auth/login', '_self')
          }
        >
          Back to Khotbot
        </OutlinedButton>
      </div>
      {/* Ticker tape */}
      <AppTickerTape className='mb-[13px] mobile:mb-[23px]' />
      {/* ---- BANNER ---- */}
      <Banner className='flex-1 mb-[50px] mobile:mb-[20px]' />
      {/* ---- Announcement ---- */}
      <ActiveAnnouncement className='mb-[50px] mobile:mb-[20px]' />
      {/* ---- Helper buttons ---- */}
      <div className='flex justify-between space-x-2 desktop:mb-8 mb-5'>
        <HelperButton
          onClick={() =>
            navigate({
              pathname: OtherRoutesPath.ranking.absolutePath,
              search: `?type=${ProfitType.ALL_TIME}`,
            })
          }
          label='Ranking'
          icon={<CrownWhiteIcon className='w-full h-full' />}
        />

        <HelperButton
          onClick={() => navigate(OtherRoutesPath.apiManagement.absolutePath)}
          label={
            <span>
              API <br /> management
            </span>
          }
          icon={<PlugConnectedIcon className='w-full h-full' />}
        />
        <HelperButton
          onClick={() => navigate(OtherRoutesPath.calculator.absolutePath)}
          label={
            <span>
              Budget
              <br />
              calculator
            </span>
          }
          icon={<CalculatorIcon className='w-full h-full' />}
        />
        <HelperButton
          onClick={() => navigate(OtherRoutesPath.changePassword.absolutePath)}
          label={
            <span>
              Change
              <br />
              Password
            </span>
          }
          icon={<PasswordIcon className='w-full h-full' />}
        />
        <HelperButton
          data-test-id='other-menu'
          onClick={openOtherModal}
          label='Other'
          icon={<BurgerIcon className='w-full h-full text-white' />}
        />
      </div>
      {/* ---- Total PROFIT DESKTOP ---- */}
      <TotalProfit className='desktop:mb-[50px] mb-[20px]' />
      {/* ---- Profit ---- */}
      <div className='desktop:mb-[50px] mb-[20px]'>
        <div className='flex justify-between items-center'>
          <p className='flex-1 text-sm font-bold'>Today&#x27;s profit</p>
          <SegmentedControl
            options={marketTypeOptions}
            onChanged={v => setBotMarketType(BotMarketType[v])}
            value={botMarketType}
            className='w-[200px]'
          />
        </div>
        <ProfitByBot
          isHomeUi
          limit={isMobile ? 5 : 10}
          isShowPagination={false}
          page={profitByBotPage}
          botMarketType={botMarketType}
          pageChange={profitByBotChange}
          sortDate='day'
          className='my-[18px]'
          botClassName={`
            grid gap-x-7 gap-y-4
            sm:grid-cols-1
            md:grid-cols-2 
            lg:grid-cols-2 
          `}
        />
        <div className='mt-4 text-[10px] text-white text-right'>
          <span
            className='cursor-pointer text-gray-400'
            onClick={() =>
              navigate({
                pathname: ProfitRoutesPath.profit.path,
                search: `?type=${botMarketType}`,
              })
            }
          >
            See more
          </span>
        </div>
      </div>

      {/* TOP FIVE USERS */}
      <HomeTopFiveUsers />

      {/* ---- Trending coin ---- */}
      <div>
        <p className='text-sm font-bold'>Coin Suggestion</p>
        <TrendingCoins limit={isMobile ? 5 : 10} className='' />
        <div className='mt-4 text-[10px] text-gray-400 text-right'>
          <span
            className='cursor-pointer'
            onClick={() => navigate(TrendingCoinRoutesPath.trendingCoin.path)}
          >
            See more
          </span>
        </div>
      </div>
    </div>
  )
}

export default HomePage
