import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import { withAuthenGuard } from '@libs/hoc/withAuthenGuard'
import MenuBar from '@components/AppLayout/components/MenuBar'
import { DemoIcon } from '@components/Icons/Icons'
import React from 'react'
import { withHomeLayout } from './withHomeLayout'

const HomeLayout = ({ children }: FunctionComponentType) => {
  return (
    <>
      <div className='relative pt-[40px] pb-[80px] min-h-screen bg-gray-900'>
        <DemoIcon className='pointer-events-none fixed top-0 right-0 z-[100] h-[100px] w-[100px] mobile:h-[70px] mobile:w-[70px]' />
        {children}
      </div>
      <MenuBar />
    </>
  )
}

export default withAuthenGuard(withHomeLayout(HomeLayout))
