import ScaleLoader from 'react-spinners/ScaleLoader'

function Loading({ text }: { text: string }) {
  return (
    <div className='w-full'>
      <div className='w-max mx-auto mt-5 flex flex-col space-y-3 items-center'>
        <p className='text-sm text-primary font-semibold'>{text}</p>
        <ScaleLoader
          height='15px'
          width='4px'
          radius='10px'
          margin='4px'
          loading
          color='#E86D14'
        />
      </div>
    </div>
  )
}

export default Loading
