import { useLocation, useNavigate } from 'react-router-dom'
import {
  CoinSpotActiveIcon,
  CoinSpotIcon,
  ContractActiveIcon,
  ContractIcon,
  HomeActiveIcon,
  HomeIcon,
} from '@components/Icons/Icons'
import {
  ContractRoutesPath,
  HomeRoutesPath,
  SpotRoutesPath,
} from '@config/Router'

const menus = [
  {
    id: 'portal',
    title: 'Home',
    url: HomeRoutesPath.home.path,
    icon: <HomeIcon className='w-[30px] h-[30px]' />,
    activeIcon: <HomeActiveIcon className='w-[30px] h-[30px] fill-primary' />,
  },

  {
    id: 'spot',
    title: 'Spot',
    url: SpotRoutesPath.dashboard.absolutePath.concat('?tab=RUNNING'),
    icon: <CoinSpotIcon className='w-[30px] h-[30px]' />,
    activeIcon: (
      <CoinSpotActiveIcon className='w-[30px] h-[30px] text-primary' />
    ),
    dataTestId: 'spot-menu',
  },
  {
    id: 'contract',
    title: 'Contract',
    url: ContractRoutesPath.dashboard.absolutePath.concat('?tab=RUNNING'),
    icon: <ContractIcon className='w-[30px] h-[30px]' />,
    activeIcon: (
      <ContractActiveIcon className='w-[30px] h-[30px] text-primary' />
    ),
    dataTestId: 'contract-menu',
  },
]

const MenuBar = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return (
    <div className='fixed flex justify-between items-center w-full pt-[8px] pb-[10px] z-10 bg-navBar bottom-0 px-[123px] mobile:px-[32px]'>
      {menus.map(menu => {
        const isActive =
          pathname.includes(menu.id) || (pathname === '/' && menu.id === 'home')

        return (
          <div
            key={menu.title}
            className={`flex flex-col items-center cursor-pointer select-none ${
              isActive ? 'text-primary' : 'text-white'
            }`}
            onClick={() => navigate(menu.url)}
            data-test-id={menu.dataTestId}
          >
            {isActive ? menu.activeIcon : menu.icon}
            <div className='text-[10px] font-medium'>{menu.title}</div>
          </div>
        )
      })}
    </div>
  )
}

export default MenuBar
