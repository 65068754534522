import { useNavigate } from 'react-router-dom'
import React from 'react'
import HelperButton from '@features/home/components/HelperButton/HelperButton'
import { LogoutIcon, PrivacyIcon, TermsIcon } from '@components/Icons/Icons'
import { PolicyRoutesPath } from '@config/Router'
import { useDispatch } from 'react-redux'
import { logout } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import { botApi, exchangeApi, mainApi } from '@client/client'
import { useLogoutMutation } from '@client/mainClient/collections/LogOutClientAction'

const HelperMenusModal = ({ toggleModal }: { toggleModal: () => void }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleNavigate = (pathTo: string) => {
    toggleModal()
    navigate(pathTo)
  }

  const [logoutMutate] = useLogoutMutation()
  const handleLogout = () => {
    toggleModal()
    logoutMutate()
    dispatch(logout())
    dispatch(botApi.util.resetApiState())
    dispatch(mainApi.util.resetApiState())
    dispatch(exchangeApi.util.resetApiState())
  }
  return (
    <div className='grid grid-cols-3 gap-x-4 gap-y-4 justify-items-center p-10 mobile:p-5 desktop:w-[540px] w-[270px]'>
      <HelperButton
        onClick={() =>
          handleNavigate(PolicyRoutesPath.termsOfService.absolutePath)
        }
        label={
          <span>
            Terms of
            <br />
            service
          </span>
        }
        icon={<TermsIcon className='w-full h-full' />}
      />
      <HelperButton
        onClick={() => handleNavigate(PolicyRoutesPath.privacy.absolutePath)}
        label={
          <span>
            User privacy
            <br />
            notice
          </span>
        }
        icon={<PrivacyIcon className='w-full h-full' />}
      />
      <HelperButton
        onClick={() => handleLogout()}
        label='Log out'
        icon={<LogoutIcon className='w-full h-full' />}
      />
    </div>
  )
}

export default HelperMenusModal
