import { withAuthenGuard } from '@libs/hoc/withAuthenGuard'
import { withUnActivateGuard } from '@libs/hoc/withUnActivateGuard'
import { DemoIcon } from '@components/Icons/Icons'
import { withAppLayout } from './withAppLayout'
import NavBar from './components/NavBar/NavBar'
import MenuBar from './components/MenuBar'
import { AppLayoutProps } from './interface'
import NavBarBackButton from './components/NavBarBackButton/NavBarBackButton'

const AppLayout = ({ children, title }: AppLayoutProps) => {
  return (
    <>
      <DemoIcon className='pointer-events-none fixed top-0 right-0 z-[100] h-[100px] w-[100px] mobile:h-[70px] mobile:w-[70px]' />
      {title ? <NavBarBackButton title={title} /> : <NavBar />}
      <div className='py-[70px] min-h-screen bg-gray-900'>{children}</div>
      <MenuBar />
    </>
  )
}
const AppLayoutWithAuthGuard = withAuthenGuard(withAppLayout(AppLayout))
const AppLayoutWithActivateGuard = withAuthenGuard(
  withAppLayout(withUnActivateGuard(AppLayout))
)
export {
  AppLayoutWithAuthGuard as AppLayout,
  AppLayoutWithActivateGuard,
  AppLayout as AppLayoutWithNoGuard,
}
