import React from 'react'
import Privacy from './Privacy'

const PrivacyModal = props => {
  return (
    <div className='w-[500px] h-4/5-screen  overflow-y-auto mx-3 my-4'>
      <Privacy className='px-2' />
    </div>
  )
}
export default PrivacyModal
