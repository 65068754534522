import { Navigate, Outlet } from 'react-router-dom'
import { AppLayout } from '@components/AppLayout/AppLayout'
import { HomeRoutesPath, otherPath, OtherRoutesPath } from '@config/Router'
import SettingKeyPage from './pages/SettingKeyPage/SettingKeyPage'
import ChangePasswordPage from './pages/ChangePasswordPage/ChangePasswordPage'
import SettingProfilePage from './pages/SettingProfilePage/SettingProfilePage'
import RankingPage from './pages/Ranking/RankingPage'
import BudgetCalculatorPage from './pages/BudgetCalculatorPage/BudgetCalculatorPage'
import RankingChartPage from './pages/RankingChartPage/RankingChartPage'

export const OtherRoutes = {
  path: otherPath,
  element: <Outlet />,
  children: [
    {
      path: '',
      element: <Navigate to={HomeRoutesPath.home.path} />,
    },
    {
      path: OtherRoutesPath.apiManagement.path,
      element: (
        <AppLayout title='API Management'>
          <SettingKeyPage />
        </AppLayout>
      ),
    },
    {
      path: OtherRoutesPath.changePassword.path,
      element: (
        <AppLayout title='Change password'>
          <ChangePasswordPage />
        </AppLayout>
      ),
    },
    // {
    //   path: OtherRoutesPath.referral.path,
    //   element: (
    //     <AppLayout title='Referral program'>
    //       <ReferralPage />
    //     </AppLayout>
    //   ),
    // },
    {
      path: OtherRoutesPath.profileSetting.path,
      element: (
        <AppLayout title='Profile'>
          <SettingProfilePage />
        </AppLayout>
      ),
    },
    {
      path: OtherRoutesPath.ranking.path,
      element: (
        <AppLayout title='Ranking'>
          <RankingPage />
        </AppLayout>
      ),
    },
    // {
    //   path: OtherRoutesPath.coupon.path,
    //   element: (
    //     <AppLayout title='Coupon'>
    //       <RedeemCouponPage />
    //     </AppLayout>
    //   ),
    // },
    {
      path: OtherRoutesPath.calculator.path,
      element: (
        <AppLayout title='Budget calculator'>
          <BudgetCalculatorPage />
        </AppLayout>
      ),
    },
    {
      path: OtherRoutesPath.ranking.chart.path,
      element: (
        <AppLayout title='Ranking chart'>
          <RankingChartPage />
        </AppLayout>
      ),
    },
  ],
}
