import { useNavigate } from 'react-router-dom'
import { RobotIcon, StrategyIcon } from '@components/Icons/Icons'
import { ContractRoutesPath, SpotRoutesPath } from '@config/Router'
import React from 'react'
import { CreateContractMenuPageProps } from '@features/bot/pages/CreateBotMenuPage/interface'
import withCreateBotMenuPage from '@features/bot/pages/CreateBotMenuPage/withCreateBotMenuPage'
import { BotMarketType } from '@interfaces/BotMarketEnum'

const createContractMenus = [
  {
    id: 'Create bot',
    title: 'Create bot',
    icon: <RobotIcon height='32' width='32' />,
    url: ContractRoutesPath.createBot.absolutePath,
  },
  {
    id: 'Manage strategy',
    title: 'Manage strategy',
    icon: <StrategyIcon height='32' width='32' />,
    url: ContractRoutesPath.manageStrategy.absolutePath,
  },
]

const createSpotMenus = [
  {
    id: 'Create bot',
    title: 'Create bot',
    icon: <RobotIcon height='32' width='32' />,
    url: SpotRoutesPath.createBot.absolutePath,
  },
  {
    id: 'Manage strategy',
    title: 'Manage strategy',
    icon: <StrategyIcon height='32' width='32' />,
    url: SpotRoutesPath.manageStrategy.absolutePath,
  },
]

const CreateBotMenuPage = ({
  isHasAuthExchangeKey,
  botMarketType,
}: CreateContractMenuPageProps) => {
  const navigate = useNavigate()
  const botRoute =
    botMarketType === BotMarketType.FUTURE
      ? createContractMenus
      : createSpotMenus

  return (
    <div className='max-w-[700px] mx-auto desktop:mt-2 mb-9'>
      <div className='mt-[37px] px-[16px] flex flex-col gap-y-[24px]'>
        <div
          className={`
            desktop:flex
            grid grid-cols-2 gap-x-9 gap-y-9 justify-items-center self-center
          `}
        >
          {botRoute.map(menu => (
            <div
              key={menu.id}
              className={`
                flex flex-col items-center justify-center space-y-[15px] 
                w-[120px] h-[120px] p-[10px] 
                border-1 border-gray-300 rounded-[10px] cursor-pointer
                hover:border-primary hover:text-primary
                `}
              onClick={() => navigate(menu.url)}
            >
              {menu.icon}
              <span className='text-xs font-medium whitespace-nowrap'>
                {menu.title}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
const WrappedComponent = withCreateBotMenuPage(CreateBotMenuPage)
export default WrappedComponent
