import { ProfitGroupBy } from '@models/Profit/ProfitGroupBy'
import { CoinIcon, InfoIcon } from '@components/Icons/Icons'
import TotalProfit from '@components/Profit/TotalProfit/TotalProfit'
import TabBar from '@components/TabBar/TabBar'

import ProfitByBot from '@components/Profit/ProfitByBot/ProfitByBot'
import ProfitByCoin from '@components/Profit/ProfitByCoin/ProfitByCoin'
import DatePickerSimpleField from '@components/form/DatePickerSimpleField/DatePickerSimpleField'
import SegmentedControl from '@components/SegmentedControl/SegmentedControl'
import { OptionType } from '@interfaces/OptionType'
import { BotMarketType } from '@interfaces/BotMarketEnum'
import { ProfitPageProps } from './interface'
import withProfitPage from './withProfitPage'

const options: OptionType[] = [
  {
    label: 'Coin',
    value: 'COIN',
  },
  {
    label: 'Bot',
    value: 'BOT',
  },
]
const marketTypeOptions: OptionType[] = [
  {
    label: 'Futures',
    value: BotMarketType.FUTURE,
  },
  {
    label: 'Spot',
    value: BotMarketType.SPOT,
  },
]
function ProfitPage({
  groupBy,
  sortDate,
  page,
  startDate,
  endDate,
  handleGroupByChange,
  handleSortDateChange,
  pageChange,
  handleOnSelectDates,
  botMarketType,
  handleOnBotMarketTypeChange,
  maxDate,
}: ProfitPageProps) {
  return (
    <div className='max-w-[700px] mx-auto px-[15px] pt-[14px] pb-[58px]'>
      <div className='flex items-center justify-between mb-[13px]'>
        <div className='flex items-center'>
          <CoinIcon />
          <div className='ml-[8px] '>My Profit (USDT/THB)</div>
        </div>
        <div>
          <SegmentedControl
            options={marketTypeOptions}
            onChanged={value =>
              handleOnBotMarketTypeChange(BotMarketType[value])
            }
            value={botMarketType}
            className='w-[200px]'
          />
        </div>
      </div>
      <div className='mt-[32px] mx-10 mobile:mx-0'>
        <TotalProfit />
      </div>
      <p className='text-xs mt-5 items-start flex  p-3 flex-1 rounded-[5px] border-[1px] border-gray-600 bg-gray-800 '>
        <InfoIcon className='w-16 h-16 sm:w-8 sm:h-8 stroke-0 mr-2 pb-[46px] sm:pb-[10px]' />
        <span>
          The transaction shown below still does not include the trading cost
          from Binance. Trading costs are deducted from the P&L calculation.
          Account level P&L analysis includes transactional P&L and fees, and
          unrealized exchange rate P&L from holding assets, all valued on USD
          basis.
        </span>
      </p>
      <div className='mt-[16px]'>
        <TabBar
          activeTab={sortDate}
          tabs={[
            { value: 'day', label: 'Daily' },
            { value: 'week', label: 'Weekly' },
            { value: 'month', label: 'Monthly' },
          ]}
          handleTabChange={handleSortDateChange}
        />
      </div>

      <SegmentedControl
        options={options}
        onChanged={handleGroupByChange}
        value={groupBy}
        className={`my-[16px] `}
      />

      <div
        className={`mb-[14px] flex space-x-2 ${
          groupBy === ProfitGroupBy.COIN && sortDate === 'day' ? '' : 'hidden'
        }`}
      >
        <DatePickerSimpleField
          name='dateRange'
          value={startDate.toJSDate()}
          startDate={startDate.toJSDate()}
          endDate={endDate?.toJSDate()}
          handleOnSelectDateRange={handleOnSelectDates}
          placeholder='Select date range'
          maxDate={maxDate}
          selectsRange
        />
      </div>

      <div className='mb-[16px]'>
        {ProfitGroupBy.BOT === groupBy && (
          <ProfitByBot
            limit={10}
            sortDate={sortDate}
            isShowPagination
            page={page}
            pageChange={pageChange}
            botMarketType={botMarketType}
          />
        )}

        {ProfitGroupBy.COIN === groupBy && (
          <ProfitByCoin
            sortDate={sortDate}
            startDate={startDate}
            endDate={endDate}
            botMarketType={botMarketType}
          />
        )}
      </div>
    </div>
  )
}

export default withProfitPage(ProfitPage)
